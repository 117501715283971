import {
  GetRequest,
  PutRequestForm,
  DeleteRequest,
  PostRequestJson,
  PutRequestJson,
} from "src/utils/rest_api_util";
import { config } from "src/config/config";
import { isBlank } from "src/utils/validate_util";
import { AssetBusinessCond, MvpApiVersion } from "./api_types";

export const getAssetsCreatedByUserAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.created_assets}`;
  const data: Record<string, any> = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    sort_by: options.sortBy,
  };
  if (!isBlank(options.assetType)) {
    data.asset_type = options.assetType;
  }
  if (!isBlank(options.mediaType)) {
    data.media_type = options.mediaType;
  }
  if (!isBlank(options.bizCond)) {
    data.listing_type = options.bizCond;
  }
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, data, headers);
};

export const getKidAssetsCreatedByUserAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.created_kid_assets(options.kid)}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, null, headers);
};

export const getSerialsCreatedByUserAPI = (options: any) => {
  let url: string = "";
  url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.created_kid_assets_per_serial(
    options.kid
  )}`;

  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return GetRequest(url, null, headers);
};

export const getAssetsCollectedByUserAPI = (options: any) => {
  let url: string = "";
  if (options.kid) {
    url = `${
      config.MARKETPLACE_API.HOST
    }${config.MARKETPLACE_API.APIS_V2.owned_kid_assets(options.kid)}`;
  } else {
    url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.owned_assets}`;
  }
  const data: Record<string, any> = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    sort_by: options.sortBy,
  };
  if (!isBlank(options.assetType)) {
    data.asset_type = options.assetType;
  }
  if (!isBlank(options.mediaType)) {
    data.media_type = options.mediaType;
  }
  if (!isBlank(options.bizCond)) {
    data.listing_type = options.bizCond;
  }
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, data, headers);
};

export const getKidAssetsCollectedByUserAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.owned_kid_assets(options.kid)}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, null, headers);
};

export const getSerialsCollectedByUserAPI = (options: any) => {
  let url: string = "";
  url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.owned_kid_assets_per_serial(options.kid)}`;

  const data: Record<string, any> = {
    asset_token_ids: options.assetTokenIds,
  };

  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return GetRequest(url, data, headers);
};

export const getTradeListingSerialsAPI = (options: any) => {
  let url: string = "";
  url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.trade_listing_serial(options.kid)}`;

  const data: Record<string, any> = {
    asset_token_ids: options.assetTokenIds,
  };

  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return GetRequest(url, data, headers);
};

export const getAssetsRentedByUserAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.rented_assets}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, null, headers);
};

export const getKidAssetsRentedByUserAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.rented_kid_assets(options.kid)}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, null, headers);
};

export const getAssetsLikedByUserAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.liked_assets}`;
  const data: Record<string, any> = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    sort_by: options.sortBy,
  };
  if (!isBlank(options.assetType)) {
    data.asset_type = options.assetType;
  }
  if (!isBlank(options.mediaType)) {
    data.media_type = options.mediaType;
  }
  if (!isBlank(options.bizCond)) {
    data.listing_type = options.bizCond;
  }
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, data, headers);
};

export const getAssetsBiddingByUserAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.bidding_assets}`;
  const data: Record<string, any> = {
    offset: Number(options.pageNumber) * Number(options.pageSize),
    limit: options.pageSize,
    sort_by: options.sortBy,
  };
  if (!isBlank(options.assetType)) {
    data.asset_type = options.assetType;
  }
  if (!isBlank(options.mediaType)) {
    data.media_type = options.mediaType;
  }
  if (!isBlank(options.bizCond)) {
    data.listing_type = options.bizCond;
  }
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, data, headers);
};

export const getAssetsByUploadAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.assets_of_upload}`;
  const data: Record<string, any> = {
    transaction_id: options.transactionId,
    listed: options.listed,
  };
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, data, headers);
};

export const getAssetDetailsByUserAPI = (options: any) => {
  // TODO: Improve API url and parameters
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.asset_by_id(options.assetSerialID)}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  return GetRequest(url, null, headers);
};

export const updateAssetAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.update_asset}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data: Record<string, any> = {
    asset_token_id: options.assetTokenID,
    title: options.title,
    description: options.description,
    brand_id: options.brand_id,
    group_key: options.group_key,
    asset_type: options.assetType,
    batch: options.batch,
    creator_royalty_rate: options.creatorRoyaltyRate,
    rental_creator_royalty_rate: options.rentalCreatorRoyaltyRate,
    sale_starting_at: options.saleStartingAt,
    sale_ending_at: options.saleEndingAt,
    secondary_sale_enabled: options.secondarySaleEnabled,
    trade_enabled: options.tradeEnabled,
    secondary_rental_enabled: options.secondaryRentalEnabled,
    only_japan_accessible: options.onlyJapanAccessible,
    min_sellable_price: options.minSellablePrice,
    download_enabled: options.downloadEnabled,
  };
  return PutRequestJson(url, data, headers);
};

export const deleteAssetAPI = (options: any) => {
  const urlString = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.delete_asset}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  let url = new URL(urlString);
  url.searchParams.set("asset_id", options.assetTokenID);
  url.searchParams.set("type_of_token", options.typeOfToken);

  return DeleteRequest(url.href, headers);
};

export const delistAssetAPI = (options: any) => {
  const headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.delist_asset}`;
  const data: Record<string, any> = {
    assets: options.assets,
    batch: options.batch,
  };

  return PostRequestJson(url, data, headers);
};

export const copyAssetAPI = (options: any) => {
  let headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  let url: string = "";
  let data: Record<string, any> = {
    title: options.title,
    description: options.description,
    kid: options.kid,
    copies_count: options.copies_count,
    asset_type: options.asset_type,
  };

  if (
    options.mvpVersion === undefined ||
    options.mvpVersion === MvpApiVersion.MVP_V10
  ) {
    url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.copy_asset}`;
  } else if (options.mvpVersion === MvpApiVersion.MVP_V20) {
    url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.copy_asset}`;
    headers["MvpVersion"] = MvpApiVersion.MVP_V20;
  } else {
    url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.copy_asset_x}`;
  }
  return PostRequestJson(url, data, headers);
};

export const listAssetAPI = (options: any) => {
  let headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  let url: string = "";
  let data: Record<string, any> = {
    asset_token_ids: options.assetTokenIDs,
    sale_price: options.salePrice,
    rent_price: options.rentPrice,
    rental_period_seconds: options.rentalPeriod,
    batch: options.batch,
  };
  if (
    options.mvpVersion === undefined ||
    options.mvpVersion === MvpApiVersion.MVP_V10
  ) {
    url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.list_asset}`;
  } else if (options.mvpVersion === MvpApiVersion.MVP_V20) {
    url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.list_asset}`;
    headers["MvpVersion"] = MvpApiVersion.MVP_V20;

    data["auction_price"] = options.auctionPrice;
    data["auction_start_timestamp"] = options.auctionStart;
    data["auction_period_seconds"] = options.auctionPeriod;
    data["min_bid_increment"] = options.minBidIncrement;
  } else {
    url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.list_asset_x}`;
    data["auction_price"] = options.auctionPrice;
    data["auction_start_timestamp"] = options.auctionStart;
    data["auction_period_seconds"] = options.auctionPeriod;
    data["min_bid_increment"] = options.minBidIncrement;
  }

  return PostRequestJson(url, data, headers);
};

export const offerTradingAssetAPI = (options: any) => {
  let headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  let url: string = "";
  let data: Record<string, any> = {
    accessToken: options.accessToken,
    submitted_asset_token_ids: options.submittedAssetTokenIDs,
    requested_asset_token_ids: options.requestedAssetTokenIDs,
    wait_offers: options.waitOffers,
  };
  if (
    options.mvpVersion === undefined ||
    options.mvpVersion === MvpApiVersion.MVP_V10
  ) {
    url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.offer_trading_asset}`;
  } else if (options.mvpVersion === MvpApiVersion.MVP_V20) {
    url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.offer_trading_asset}`;
    headers["MvpVersion"] = MvpApiVersion.MVP_V20;

    data["auction_price"] = options.auctionPrice;
    data["auction_start_timestamp"] = options.auctionStart;
    data["auction_period_seconds"] = options.auctionPeriod;
    data["min_bid_increment"] = options.minBidIncrement;
  } else {
    url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.list_asset_x}`;
    data["auction_price"] = options.auctionPrice;
    data["auction_start_timestamp"] = options.auctionStart;
    data["auction_period_seconds"] = options.auctionPeriod;
    data["min_bid_increment"] = options.minBidIncrement;
  }

  return PostRequestJson(url, data, headers);
};

export const calcFreeDistributionFeeAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.calc_free_distribution_fee}`;
  const headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data: Record<string, any> = {
    asset_token_ids: options.assetTokenIDs,
  };
  return PostRequestJson(url, data, headers);
};

export const distributeAssetAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.distribute_asset}`;
  const headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const allowed_asset_acquirers = options.acquirers.map((email) => {
    return { email: email };
  });
  const data: Record<string, any> = {
    asset_token_ids: options.assetTokenIDs,
    asset_acquirer_restriction: {
      allowed_asset_acquirers: allowed_asset_acquirers,
    },
    asset_acquisition_count_restriction: {
      max_count_per_wallet: options.limitOnNumber,
    },
    lang: options.lang,
  };
  return PostRequestJson(url, data, headers);
};

export const createFreeRentalSettingAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.free_rental_setting}`;
  const headers: Record<string, any> = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const allowed_asset_renters = options.renters.map((email) => {
    return { email: email };
  });
  const data: Record<string, any> = {
    asset_token_ids: options.assetTokenIDs,
    rental_period_seconds: options.rentalPeriodSeconds,
    asset_renter_restriction: {
      allowed_asset_renters: allowed_asset_renters,
    },
  };
  return PostRequestJson(url, data, headers);
};

export const completeAuctionAPI = (options: any) => {
  const url = `${config.MARKETPLACE_API.HOST}${config.MARKETPLACE_API.APIS_V2.complete_auction}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };
  const data: Record<string, any> = {
    asset_token_id: options.assetTokenID,
  };
  return PostRequestJson(url, data, headers);
};

export const getAssetMintStatusAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.mint_status(options.transactionId)}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return GetRequest(url, null, headers);
};

export const getFlowTransactionStatusAPI = (options: any) => {
  const url = `${
    config.MARKETPLACE_API.HOST
  }${config.MARKETPLACE_API.APIS_V2.flow_tx_status(options.transactionId)}`;
  const headers = {
    Authorization: `Bearer ${options.accessToken}`,
  };

  return GetRequest(url, null, headers);
};
